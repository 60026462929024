<template>
  <section class="sticky-quiz-top">
    <Linkbar
      v-if="quizData.linkbar"
      :content="quizData.linkbar"
    />
    <OverhangHeader
      v-if="quizData.header"
      :content="quizData.header"
    />
  </section>
</template>

<script>
import Linkbar from '../components/Linkbar.vue'
import OverhangHeader from '../components/OverhangHeader.vue'

export default {
  components: {
    Linkbar,
    OverhangHeader,
  },
  data() {
    return {
      quizData: this.$quizData,
    }
  },
}
</script>
