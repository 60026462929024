<template>
  <div>
    <ThesisForm v-if="quizData.one_page">
      <template #headerSlot>
        <StickyQuizTop />
      </template>
      <template #onePageIntro>
        <QuizIntro />
      </template>
      <template #onePageBottom>
        <QuizBottom />
      </template>
      <template #footerSlot>
        <Footer />
      </template>
    </ThesisForm>
    <ThesisForm v-else>
      <template #headerSlot>
        <!-- Above quiz section -->
        <StickyQuizTop />
        <QuizIntro />
        <!-- Multi-page quiz does not have id to jump to -->
        <div id="quiz-start" />
      </template>
      <template #loaderView>
        <!-- Multi-page embed post-questions -->
        <ApplicationForm
          v-if="quizData.is_form_results && !quizData.modified_form"
        />
        <ModifiedApplicationForm v-if="quizData.modified_form" />
        <div v-else />
      </template>
      <template #footerSlot>
        <!-- Below quiz section -->
        <QuizBottom />
        <FooterTop v-if="quizData.has_footer_top" />
        <Footer />
      </template>
    </ThesisForm>
  </div>
</template>

<script>
import StickyQuizTop from "./sections/StickyQuizTop.vue";
import QuizIntro from "./sections/QuizIntro.vue";
import QuizBottom from "./sections/QuizBottom.vue";
import Footer from "./sections/Footer.vue";
import FooterTop from "./sections/FooterTop.vue";

const ApplicationForm = () => import("./components/ApplicationForm.vue");
const ModifiedApplicationForm = () =>
  import("./components/ModifiedApplicationForm.vue");

export default {
  components: {
    StickyQuizTop,
    QuizIntro,
    QuizBottom,
    Footer,
    ApplicationForm,
    ModifiedApplicationForm,
    FooterTop,
  },
  data() {
    return {
      quizData: this.$quizData,
    };
  },
  computed: {
    isMultiPageQuizCompleted() {
      // Workaround used by multi-page embed quiz where form is below quiz
      // For more info check store/mutations.js
      return this.$store.getters.ONE_PAGE_VALID;
    },
  },
  watch: {
    isMultiPageQuizCompleted() {
      if (!this.quizData.one_page) {
        this.$scrollTo("#application-form", 500, {
          offset: -100,
        });
      }
    },
  },
  created() {
    this.$store.dispatch("INITIALIZE_STORE", this.$quizData);
    this.$store.dispatch(
      "/results/INITIALIZE_RESULTS",
      this.$quizData.sequences
    );
  },
};
</script>
