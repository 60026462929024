export default {
  INITIALIZE_STORE: ({ commit, }, payload) => {
    commit('MUTATE_INITIALIZE_STORE', payload)
  },
  ADVANCE_QUIZ: ({ commit, }, payload) => {
    if (payload === 'quick-results') {
      commit('MUTATE_QUIZ_COMPLETED')
    } else {
      commit('MUTATE_ADVANCE_QUIZ', payload)
    }
  },
  REGRESS_QUIZ: ({ commit, }) => {
    commit('MUTATE_REGRESS_QUIZ')
  },
  SET_FULLREVEALED: ({ commit }) => {
    commit('MUTATE_SET_FULLREVEALED')
  },
  QUIZ_STARTED: ({ commit, }) => {
    commit('MUTATE_QUIZ_STARTED')
  },
  QUIZ_COMPLETED: ({ commit, }) => {
    commit('MUTATE_QUIZ_COMPLETED')
  },
  SET_LOADED: ({ commit, }) => {
    commit('MUTATE_SET_LOADED')
  },
  ONE_PAGE_VALID: ({ commit, }) => {
    commit('MUTATE_ONE_PAGE_VALID')
  },
}
