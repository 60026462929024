<template>
  <footer class="site-footer">
    <div class="container">
      <div
        v-if="content.expanded"
        class="footer-brand"
      >
        <TitleBlock :content="content" />
        <MediaBlock :content="content" />
      </div>
      <div class="footer-meta">
        <p class="footer-copyright">
          &copy; {{ currentYear }}
          <span v-html="content.copyright" />
        </p>
        <ul class="footer-links">
          <li
            v-for="(link, index) in content.links"
            :key="index"
          >
            <a
              target="_blank"
              :href="link.url"
            >{{ link.text }}</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import TitleBlock from '../components/shared/TitleBlock.vue'
import MediaBlock from '../components/shared/MediaBlock.vue'

export default {
  components: {
    TitleBlock,
    MediaBlock,
  },
  data() {
    return {
      content: this.$quizData.footer,
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
}
</script>
