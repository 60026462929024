<template>
  <div class="linkbar">
    <a
      v-scroll-to="{
        el: content.cta_link,
        offset: -150,
        duration: 500,
      }"
      :href="content.cta_link"
      :data-trackable="content.trackable || 'quiz'"
      :data-interaction="content.interaction || 'linkbar-click'"
      @click="handleClick"
      v-html="content.text"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleClick() {
      const { interaction, trackable } = this.content

      window.analytics.tracking.trackClick(interaction, trackable)
    },
  },
}
</script>
