<template>
  <section class="quiz-bottom">
    <ApplicationForm v-if="!quizData.is_form_results" />
    <Details
      v-if="quizData.before_after_carousel"
      :content="quizData.before_after_carousel"
    />
    <Reviews
      v-if="quizData.reviews"
      :content="quizData.reviews"
    />
    <Callout
      v-if="quizData.mid_callout"
      :content="quizData.mid_callout"
    />
    <Callout
      v-if="quizData.what_makes_us_different"
      :content="quizData.what_makes_us_different"
    />
    <Callout
      v-if="quizData.map_callout"
      :content="quizData.map_callout"
    />
    <Callout
      v-if="quizData.final_callout"
      :content="quizData.final_callout"
    />
  </section>
</template>
<script>
import ApplicationForm from '../components/ApplicationForm.vue'
import Callout from '../components/Callout.vue'
import Details from '../components/Details.vue'
import Reviews from '../components/Reviews.vue'

export default {
  components: {
    ApplicationForm,
    Details,
    Reviews,
    Callout,
  },
  data() {
    return {
      quizData: this.$quizData,
    }
  },
}
</script>
