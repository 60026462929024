<template>
  <div
    v-if="content.cta_text && content.cta_link"
    class="cta-block"
  >
    <small
      v-if="content.text_above_cta"
      class="text-above-cta"
      v-html="content.text_above_cta"
    />
    <div class="cta-wrapper">
      <a
        v-if="isAnchorLink"
        v-scroll-to="{
          el: content.cta_link,
          offset: -150,
          duration: 500,
        }"
        :href="content.cta_link"
        class="btn"
        :data-trackable="content.trackable || 'quiz'"
        :data-interaction="content.interaction || 'cta-click'"
        @click="handleClick"
        v-html="content.cta_text"
      />
      <button
        v-else-if="hasCustomClickEvent"
        class="btn action-btn"
        @click="handleClick"
        v-html="content.cta_text"
      />
      <a
        v-else
        :href="content.cta_link"
        class="btn"
        :data-trackable="content.trackable || 'quiz'"
        :data-interaction="content.interaction || 'cta-click'"
        @click="handleClick"
        v-html="content.cta_text"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    hasCustomClickEvent: {
      type: Boolean,
      default: false,
    },
    customClickEvent: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      isAnchorLink: this.content.cta_link && this.content.cta_link.includes('#'),
    }
  },
  methods: {
    handleClick() {
      if (this.hasCustomClickEvent) this.customClickEvent()

      const { interaction, trackable } = this.content

      window.analytics.tracking.trackClick(interaction, trackable)
    },
  },
}
</script>
