<template>
  <header class="site-header overhang-header">
    <div class="container">
      <div class="header-img-container">
        <a
          v-scroll-to="{
            el: content.logo_link,
            offset: -30,
            duration: 500,
          }"
          :href="content.logo_link"
        >
          <img
            class="header-logo"
            :src="content.logo_image"
            alt="header-logo-img"
          >
        </a>
      </div>
      <div class="header-content">
        <p
          v-if="content.text"
          class="header-text"
          v-html="content.text"
        />
        <CTABlock :content="content" />
      </div>
    </div>
  </header>
</template>

<script>
import CTABlock from './shared/CTABlock.vue'

export default {
  components: {
    CTABlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
