<template>
  <section class="quiz-intro">
    <Callout
      v-if="quizData.intro_callout"
      :content="quizData.intro_callout"
    />
    <Details
      v-if="quizData.intro_details"
      :content="quizData.intro_details"
    />
    <Callout
      v-if="quizData.offer_callout"
      :content="quizData.offer_callout"
    />
    <Callout
      v-if="quizData.heading_callout"
      :content="quizData.heading_callout"
    />
    <Callout
      v-if="quizData.above_quiz_callout"
      :content="quizData.above_quiz_callout"
    />
  </section>
</template>
<script>
import Callout from '../components/Callout.vue'
import Details from '../components/Details.vue'

export default {
  components: {
    Callout,
    Details,
  },
  data() {
    return {
      quizData: this.$quizData,
    }
  },
}
</script>
