<template>
  <blockquote
    v-if="content.quote"
    class="quote-block"
  >
    <MediaBlock :content="content" />
    <div class="quote-text-wrapper">
      <p
        class="quote-body"
        v-html="content.quote"
      />
      <small
        v-if="content.attribution"
        class="quote-attribution"
        v-html="content.attribution"
      />
    </div>
  </blockquote>
</template>

<script>
import MediaBlock from './MediaBlock.vue'

export default {
  components: {
    MediaBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
