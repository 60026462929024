<template>
  <section
    class="reviews-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <TitleBlock :content="content" />

      <div
        class="reviews-content grid"
        :class="[
          { 'is-carousel-on-mobile': content.carousel },
          `columns-${content.reviews.length}`
        ]"
      >
        <div
          v-for="(review, index) of content.reviews"
          :id="review.id"
          :key="index"
          class="review"
        >
          <QuoteBlock :content="review" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'
import QuoteBlock from './shared/QuoteBlock.vue'

export default {
  components: {
    TitleBlock,
    QuoteBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (this.content.carousel) this.initializeCarousel()
  },
  methods: {
    initializeCarousel() {
      const { content } = this

      $('.reviews-content.is-carousel-on-mobile').each(function () {
        const slider = $(this)

        if ($(this).replaceWithSVG) {
          slider.bind('DOMNodeInserted', () => {
            slider.find('[src$=".svg"]').each(function () {
              $(this).replaceWithSVG()
            })
          })
        }

        slider.slick({
          mobileFirst: true,
          responsive: content.responsive_settings || [
            {
              breakpoint: 0,
              settings: {
                arrows: false,
                dots: true,
                slidesToScroll: 1,
                slidesToShow: 1,
              },
            },
          ],
        })
      })
    },
  },
}
</script>
