<template>
  <section
    id="application-form"
    class="application-form page-section"
    :class="[{ 'with-progress-bar': isFormResults }, content.classes]"
  >
    <div class="container">
      <TitleBlock :content="content" />
      <form
        id="form-start"
        @submit.prevent="handleSubmit"
      >
        <div class="form-content">
          <fieldset :class="{ 'has-error': errors.firstName }">
            <label for="first-name">
              {{ errors.firstName ? 'Please provide first name' : 'First name' }}
            </label>
            <input
              id="first-name"
              v-model="formData.firstName"
              type="text"
              placeholder="First name"
            >
          </fieldset>
          <fieldset :class="{ 'has-error': errors.lastName }">
            <label for="last-name">
              {{ errors.lastName ? 'Please provide last name' : 'Last name' }}
            </label>
            <input
              id="last-name"
              v-model="formData.lastName"
              type="text"
              placeholder="Last name"
            >
          </fieldset>
          <fieldset :class="{ 'has-error': errors.email }">
            <label for="email">
              {{ errors.email ? 'Please provide valid email' : 'Email' }}
            </label>
            <input
              id="email"
              v-model="formData.email"
              type="email"
              placeholder="name@example.com"
            >
          </fieldset>
          <fieldset :class="{ 'has-error': errors.phone }">
            <label for="phone-number">
              {{ errors.phone ? 'Please provide valid phone number' : 'Phone number' }}
            </label>
            <the-mask
              id="phone-number"
              v-model="formData.phone"
              name="phone-number"
              autocomplete="off"
              mask="(###) ###-####"
              :masked="false"
              pattern="^\(?([2-9][0-8][0-9])\)?[\s]?([2-9][0-9]{2})[\-]?([0-9]{4})$"
              title="(123) 456-7890"
              type="tel"
              placeholder="(000) 000-0000"
            />
          </fieldset>
          <fieldset :class="{ 'has-error': errors.zipcode }">
            <label for="zipcode">
              {{ errors.zipcode ? 'Please provide valid zip code' : 'Zip Code' }}
            </label>
            <the-mask
              id="zipcode"
              v-model="formData.zipcode"
              autocomplete="off"
              type="text"
              mask="#####"
              :masked="false"
              size="5"
              minlength="5"
              pattern="^[0-9]{5}(?:-[0-9]{4})?$"
              placeholder="12345"
            />
          </fieldset>
        </div>

        <transition name="error-slide-in">
          <p
            v-if="isQuizIncompleteError"
            class="error-msg is-general"
          >
            Please answer the questions above the form.
          </p>
        </transition>

        <div class="form-submit">
          <button
            class="btn"
            :class="{ 'disabled': isDisabled }"
            type="submit"
            :disabled="isDisabled || null"
            v-html="content.cta_text || 'Submit'"
          />
          <small
            v-if="content.cta_fine_print"
            class="cta-fine-print"
            v-html="content.cta_fine_print"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import {
  required,
  email as emailValidation,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import TitleBlock from './shared/TitleBlock.vue'

export default {
  components: {
    TitleBlock,
  },
  data() {
    return {
      content: this.$quizData.applicationForm || {},
      isFormResults: this.$quizData.is_form_results,
      formData: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        zipcode: null,
      },
      errors: {
        email: false,
        firstName: false,
        lastName: false,
        phone: false,
        zipcode: false,
      },
      resultData: {},
      isQuizIncompleteError: false,
      isSubmitting: false,
    }
  },
  validations: {
    formData: {
      email: { emailValidation, required },
      firstName: { required },
      lastName: { required },
      phone: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      zipcode: {
        maxLength: maxLength(5),
        minLength: minLength(5),
        required,
      },
    },
  },
  computed: {
    quizResults() {
      return this.$store.getters.RESULTS
    },
    isDisabled() {
      return this.isSubmitting || this.$v.$error || this.$v.$anyError
    },
  },
  methods: {
    async handleSubmit() {
      this.validateQuizAnswers()
      if (this.validateForm() && !this.isQuizIncompleteError) {
        this.isSubmitting = true
        this.getResultData()

        try {
          await this.sendDataToFormCollector()
          await this.sendDataToLeadPerfection()
        } catch (error) {
          window.console.error(`Error: ${error}`)
        } finally {
          window.analytics.tracking.trackClick('form-submit', 'quiz')
          window.analytics.tracking.submitForm()

          window.location.href = '/thank-you'
        }
      }

      return false
    },
    async sendDataToFormCollector() {
      const data = await fetch('https://collector.thesistestingapps.com/insert/apps/chr/forms/quiz-1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...this.resultData,
          timestamp: this.getTimestamp(),
        }),
      })

      const res = await data.json()
      return res
    },
    async sendDataToLeadPerfection() {
      const postData = new URLSearchParams()
      const {
        firstName,
        lastName,
        email,
        phone,
        zipcode,
        notes,
      } = this.resultData

      postData.append('sender', 'Thesis')
      postData.append('firstname', firstName)
      postData.append('lastname', lastName)
      postData.append('email', email)
      postData.append('phone1', phone)
      postData.append('zip', zipcode)
      postData.append('srs_id', this.content.srs_id)
      postData.append('notes', notes)

      const data = await fetch('https://q165.leadperfection.com/batch/addleads.asp', {
        method: 'POST',
        body: postData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      const res = await data.json()
      return res
    },
    validateForm() {
      this.$v.$touch()
      const { formData } = this
      const keys = Object.keys(formData)
      this.clearErrors(keys)
      if (this.$v.$anyError) {
        // eslint-disable-next-line no-restricted-syntax
        keys.forEach(key => {
          if (this.$v.formData[key] && this.$v.formData[key].$anyError) {
            this.errors[key] = true
          }
        })
        return false
      }
      return true
    },
    clearErrors(keys) {
      keys.forEach(key => {
        this.errors[key] = false
      })
    },
    padZero(shorty) {
      return (shorty.toString()).padStart(2, '0')
    },
    getTimestamp() {
      const rightNow = new Date()
      const monthNow = this.padZero(rightNow.getMonth() + 1)
      const dateNow = this.padZero(rightNow.getDate())
      const hourNow = this.padZero(rightNow.getHours())
      const minuteNow = this.padZero(rightNow.getMinutes())
      const secondNow = this.padZero(rightNow.getSeconds())
      return `${rightNow.getFullYear()}-${monthNow}-${dateNow} ${hourNow}:${minuteNow}:${secondNow}`
    },
    getResultData() {
      // Get answer for each question
      const questionData = this.quizResults.map(result => `${result.label} [${Array.isArray(result.content)
        ? result.content.map(content => content.selection).join(',')
        : result.content}]`)
      this.resultData = {
        ...this.formData,
        notes: JSON.stringify(questionData.join(',')),
      }
    },
    validateQuizAnswers() {
      const results = this.quizResults.map(i => i.content.toString())
      if (results.includes('')) {
        this.isQuizIncompleteError = true
      } else {
        this.isQuizIncompleteError = false
      }
    },
  },
}
</script>

<style scoped>
.btn.disabled {
  background: #c4c4c4;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
