<template>
  <div class="top-footer">
    <div class="grouped">
      <MediaBlock :content="content.image_one" />
      <TitleBlock :content="content.title_one" />
    </div>
    <div class="grouped">
      <MediaBlock :content="content.image_two" />
      <TitleBlock :content="content.title_two" />
    </div>
    <MediaBlock :content="content.image_three" />
  </div>
</template>

<script>
import TitleBlock from "../components/shared/TitleBlock.vue";
import MediaBlock from "../components/shared/MediaBlock.vue";
export default {
  name: "FooterTop",
  components: {
    TitleBlock,
    MediaBlock,
  },
  data() {
    return {
      content: {
        image_one: this.$quizData.image_one,
        title_one: this.$quizData.title_one,
        image_two: this.$quizData.image_two,
        title_two: this.$quizData.title_two,
        image_three: this.$quizData.image_three,
      },
    };
  },
};
</script>