<template>
  <div
    v-if="title || text"
    class="title-block"
  >
    <h6
      v-if="kicker"
      v-html="kicker"
    />
    <h2
      v-if="title"
      v-html="title"
    />
    <h3
      v-if="subtitle"
      v-html="subtitle"
    />
    <p
      v-if="text"
      class="lead"
      v-html="text"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const {
      kicker,
      title,
      subtitle,
      text,
    } = this.content
    return {
      kicker,
      title,
      subtitle,
      text,
    }
  },
}
</script>
